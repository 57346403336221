import React from "react";

import type { HeaderGroupProps } from "components/common/headers/HeaderGroup/HeaderGroup";
import PageTitle, { type PageTitleProps } from "components/common/other/PageTitle/PageTitle";
import SquareImage from "components/images/ImageSquare/ImageSquare";
import Layout from "components/layout/Layout/Layout";
import HomeProjects from "components/pages/home/HomeProjects/HomeProjects";
import ProjectInformation, {
  type ProjectInformationProps
} from "components/pages/project/ProjectInformation/ProjectInformation";
import MainCloud from "components/rendering/MainCloud/MainCloud";
import ProjectData from "data/projects-page-data.json";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Circ } from "gsap";
import type { PageProps } from "pages/index";
import { GetSeoData } from "utils/seo-utils";

const TheLabz = ({ location }: PageProps) => {
  return (
    <>
      <GatsbySeo {...GetSeoData("TheLabz")} />
      <Layout location={location}>
        <MainCloud color="BluePink" variant="MainCloudWrapper" scrollSpeed={-2} />
        <PageTitle {...(ProjectData.TheLabz.pageTitleData as PageTitleProps)} />
        <ProjectInformation {...(ProjectData.TheLabz as ProjectInformationProps)} />
        <SquareImage
          title="TheLabz_Header_Freedom"
          imageName="TheLabz1Rect"
          description="TheLabz_Text_Freedom"
          dropText
        />
        <SquareImage
          title="TheLabz_Header_Drag"
          imageName="TheLabz10Rect"
          description="TheLabz_Text_Drag"
          left
          dropText
        />
        {/* TODO:Readd this after more materials <ProjectSideImages imageNameLeft="TheLabz3Rect" imageNameRight="TheLabz4Rect" /> */}
        <SquareImage
          title="TheLabz_Header_Order"
          imageName="TheLabz3Rect"
          description="TheLabz_Text_Order"
          dropText
        />
        <SquareImage
          title="TheLabz_Header_Engage"
          imageName="TheLabz5Rect"
          description="TheLabz_Text_Engage"
          left
        />
        {/* TODO:Readd this after more materials <ImagesCarousel {...ProjectData.TheLabz.carouselData} /> */}
        <HomeProjects headerData={headerData} />
      </Layout>
    </>
  );
};

export default TheLabz;

const headerData: HeaderGroupProps = {
  instantPlayback: false,
  animationPlaybackOffset: -0.75,
  headers: [
    {
      text: "Projects_Header_Other",
      variant: "HomeProjectsOur",
      id: "home-projects-our",
      animation: { x: "-20%", opacity: 0, duration: 0.75, delay: 0, ease: Circ.easeOut },
      horizontalScrollSpeed: -0.75
    },
    {
      text: "Home_Header_Projects",
      variant: "HomeProjectsWork",
      id: "home-projects-work",
      animation: { x: "30%", opacity: 0, duration: 0.75, delay: 0.125, ease: Circ.easeOut },
      horizontalScrollSpeed: 0.75
    }
  ]
};
